<template>
  <div>

    <responsive-menu ref="menu"
                    :bind="selectedItem"
                     :title="selectedItemName"
                     :opcoes="menuOptions"
                     @click:item="action => $emit(`click:${action}`, selectedItem)"/>
    <v-data-table :headers="headers"
                  dense
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  @click:row="onClickRow"
                  @contextmenu:row.prevent="showMenuFromContextMenu"
                  :footer-props="{
                    disableItemsPerPage: true,
                    itemsPerPageOptions: [itemsPerPage],
                  }"
                  :items="elements" :loading="loading">
      <template v-slot:item.id="{item}">
        <strong>#{{ item.id }}</strong>
      </template>
      <template v-slot:item.dtVenc="{item}">
        <span>{{item.dtVenc | moment('DD/MM/YYYY')}}</span>
      </template>
      <template v-slot:item.dtPgto="{item}">
        <span>{{item.dtPgto==="" ? "Aberta" :item.dtPgto | moment('DD/MM/YYYY')}}</span>
      </template>
      <template v-slot:item.valor="{item}">
        <span>R$ {{item.valor}}</span>
      </template>
      <template v-slot:item.tipoAluno="{item}">
        <span :class="corTipoAluno(item.tipoAluno)">{{ item.tipoAluno }}</span>
      </template>
      <template v-slot:item.comprovantePagamento="{item}">
        <span>{{retornComprovantePagamento(item)}}</span>
      </template>
      
      
      <template v-slot:item.edit="{item}">
        <v-btn @click.prevent="showMenu($event, item)" small icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-card v-if="items.length === 0" class="elevation-0">
          <v-card-text class="text-center">
            <p>Não há dados disponíveis</p>
          </v-card-text>
        </v-card>
        <v-list dense class="pa-0">
          <v-list-item @click="showMenu($event, item)" v-for="item of items" :key="item.id"
                       class="list-item">
            <v-list-item-avatar>
              #{{ item.id }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{item.nome_idCliente}} <span :class="corTipoAluno(item.tipoAluno)">({{item.tipoAluno}})</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{item.descricao}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{item.dtVenc | moment('DD/MM/YYYY')}}
                <v-chip small input-value="false" outlined>{{item.dtPgto==="" ? "Aberta" :item.dtPgto | moment('DD/MM/YYYY')}}</v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action-text>
              <v-chip color="primary" class="mr-2" input-value="false" small>R$ {{item.valorFinal}}</v-chip>
            </v-list-item-action-text>
            <v-list-item-action>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>

    </v-data-table>
  </div>
</template>

<script>

import {findAllFaturasClienteLogado} from '../../../api/faturas';
import ResponsiveMenu from "../../app/ResponsiveMenu";

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "MinhasFaturasDataTable",
  components: {ResponsiveMenu},
  props: {
    searchValues: {
      type: Object,
    },
  },
  data() {
    return {
      selectedItem: null,
      menuOptions: [
        {
          action: 'viewBoleto',
          text: 'Ver Boleto',
          icon: 'mdi-receipt',
          /*hiddenWhen(item) {
            return item?.statusDaFatura !== "R";
          },*/
          disabledWhen(item) {
            return item?.statusDaFatura === "A" || item?.statusDaFatura === "P";
          },
        },
        {
          action: 'viewRecibo',
          text: 'Ver Recibo',
          icon: 'mdi-ticket-confirmation',
          hiddenWhen(item) {
            return item?.statusDaFatura !== "P";
          },
        },
        {
          action: 'sendComprovante',
          text: 'Enviar comprovante',
          icon: 'mdi-send',
          hiddenWhen(item) {
            return item?.statusDaFatura === "P" || item?.comprovantePagamento !== "";
          },
        },
      ],
      menu: {
        positionX: 0,
        positionY: 0,
        show: false
      },
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
      headers: [
        {
          text: 'Tipo',
          value: 'tipoAluno',
          align: 'start',
          width: "5%"
        },
        {
          text: 'Nº Boleto',
          value: 'id',
          align: 'start'
        },
        {
          text: 'Cliente',
          value: 'nome_idCliente',
          align: 'start'
        },
        {
          text: 'Descrição',
          value: 'descricao',
          align: 'start'
        },
        {
          text: 'Mês Ref',
          value: 'mesRef',
          align: 'start'
        },
        {
          text: 'Data Venc	',
          value: 'dtVenc',
          align: 'start'
        },
        {
          text: 'Data Pgto',
          value: 'dtPgto',
          align: 'start'
        },
        /*{
          text: 'Valor',
          value: 'valor',
          align: 'start'
        },
        {
          text: 'Val c/ Atraso',
          value: 'valorComAtraso',
          align: 'start'
        }, 
        {
          text: 'Desconto ',
          value: 'desconto',
          align: 'start'
        },*/
        {
          text: 'Valor',
          value: 'valorFinal',
          align: 'start'
        },
        {
          text: 'Comprovante Pg.',
          value: 'comprovantePagamento',
          align: 'start'
        },
        
        {
          text: 'Menu',
          width: 30,
          value: 'edit',
          align: 'end'
        }
      ],
    }
  },
  computed: {
    selectedItemName() {
      return this.selectedItem?.descricao
    }
  },
  watch: {
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    retornComprovantePagamento(item){
      if (item.statusDaFatura === "P")  return "Fatura paga";
      if (item.comprovantePagamento === "") return "Não enviado";
      return "Enviado";
    },
    corTipoAluno(tipo){
      if (tipo=="Titular"){
          return "titular";
      } else {
          return "dependente";
      }
    },
    showMenuFromContextMenu(...contextEvent) {
      const event = contextEvent[0];
      const {item} = contextEvent[1];
      this.showMenu(event, item);
    },    
    showMenu(event, item) {
      this.selectedItem = !item.item ? item : item.item;
      this.$refs.menu.open(event);
    },
    onClickRow(row) {
      this.$emit('click:row', row);
    },
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAllFaturasClienteLogado(this.pageOptions.page, this.searchValues);

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;

      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.pageOptions.page = 1;
    this.refresh();
  }
}
</script>

<style scoped lang="scss">
.list-item {
  &::v-deep {
    .v-list-item__content {
      max-width: calc(100vw - 128px);
    }
  }
}
.list-item:not(:last-child) {
  border-bottom: 1px solid #dedede
}

</style>