<template>
<v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value"
          max-width="800px"
          persistent>
  <v-card class="d-flex flex-column">
    <v-card-title>
      {{getTitle}}
      <v-spacer/>
      <v-btn icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider/>
    <v-card-text class="py-4 flex-grow-1" v-if="value">
      <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
        <v-file-input
          accept="image/*"
          label="Escolha uma imagem do comprovante"
          v-model="item.comprovante"
          :rules="[
                    this.$validators.number.required,
                  ]"
        ></v-file-input>
      </v-form>
        
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn outlined color="primary" @click="$emit('input', false)">Cancelar</v-btn>
      <v-btn  color="primary" @click="save" :disabled="!formValid">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import {enviaComprovantePagamento} from "../../../api/faturas";
import {ApiError} from "../../../api/core";

export default {
  name: "ComprovantePagamentoDialog",
  props: ['value','item'],
  data () {
    return {
        formValid: false
    }
  },
  methods: {
    async save() {
      try {
        if(this.$refs.form.validate()) {
          await enviaComprovantePagamento(this.item)
          this.$emit('input', false);
          this.$emit('onSave');
          this.$toast.success(`Comprovante enviado com sucesso.`, {
            timeout: 3000
          })

        }
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível enviar o comprovante.`, {
            timeout: 6000
          })
        }
      }
    }
  },
  computed: {
    getTitle() {
      return 'Enviar comprovante: ' + this.item?.nome_idCliente + ' - ' + this.item?.descricao
    },
  },

}
</script>

<style scoped>

</style>