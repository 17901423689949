"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadLogProcessamentoRetorno = exports.downloadArquivoRemessa = exports.downloadBoleto = exports.downloadRecibo = exports.downloadReport = exports.findAvailableOrders = exports.ReportType = void 0;
const core_1 = require("./core");
var ReportType;
(function (ReportType) {
    ReportType[ReportType["REL_CLIENTES"] = 0] = "REL_CLIENTES";
    ReportType[ReportType["REL_ANIVERSARIANTES"] = 1] = "REL_ANIVERSARIANTES";
    ReportType[ReportType["REL_AUDITORIA"] = 2] = "REL_AUDITORIA";
    ReportType[ReportType["REL_CONTROLE_RECEBIMENTO"] = 3] = "REL_CONTROLE_RECEBIMENTO";
    ReportType[ReportType["REL_HISTORICO_MATRICULA"] = 4] = "REL_HISTORICO_MATRICULA";
    ReportType[ReportType["REL_LOG_COMUNICACAO"] = 5] = "REL_LOG_COMUNICACAO";
    ReportType[ReportType["REL_INSCRICOES"] = 6] = "REL_INSCRICOES";
})(ReportType = exports.ReportType || (exports.ReportType = {}));
const ORDER_ACTIONS = {
    [ReportType.REL_CLIENTES]: "ordRelClientes",
    [ReportType.REL_ANIVERSARIANTES]: "ordRelAniversariantes",
    [ReportType.REL_CONTROLE_RECEBIMENTO]: "ordRelControleRecebimento",
    [ReportType.REL_HISTORICO_MATRICULA]: "ordRelHistoricoMatricula",
    [ReportType.REL_LOG_COMUNICACAO]: "ordRelLogComunicacao",
    [ReportType.REL_INSCRICOES]: "ordRelInscricoes",
};
const REPORT_ACTIONS = {
    [ReportType.REL_CLIENTES]: 'relatorioClientes',
    [ReportType.REL_ANIVERSARIANTES]: 'relatorioAniversariantes',
    [ReportType.REL_AUDITORIA]: 'relatorioAuditoria',
    [ReportType.REL_CONTROLE_RECEBIMENTO]: 'relatorioControleRecebimento',
    [ReportType.REL_HISTORICO_MATRICULA]: 'relatorioHistoricoMatricula',
    [ReportType.REL_LOG_COMUNICACAO]: 'relatorioLogComunicacao',
    [ReportType.REL_INSCRICOES]: 'relatorioInscricoes',
};
function findAvailableOrders(reportType) {
    return core_1.get('ordenacao', ORDER_ACTIONS[reportType])
        .then(({ dados }) => dados.map(dado => ({
        name: dado.nome,
        field: dado.campo
    })));
}
exports.findAvailableOrders = findAvailableOrders;
function downloadReport(reportType, search) {
    return core_1.downloadFile('Relatorios', REPORT_ACTIONS[reportType], Object.assign({}, search));
}
exports.downloadReport = downloadReport;
function downloadRecibo(search) {
    return core_1.downloadFile('Relatorios', 'reciboFartura', Object.assign({}, search));
}
exports.downloadRecibo = downloadRecibo;
function downloadBoleto(search) {
    return core_1.downloadFile('Relatorios', 'exibirBoleto', Object.assign({}, search));
}
exports.downloadBoleto = downloadBoleto;
function downloadArquivoRemessa(search) {
    return core_1.downloadTxt('Relatorios', 'downloadRemessa', Object.assign({}, search));
}
exports.downloadArquivoRemessa = downloadArquivoRemessa;
function downloadLogProcessamentoRetorno(search) {
    return core_1.downloadFile('Relatorios', 'exibirLogProcessamentoRetorno', Object.assign({}, search));
}
exports.downloadLogProcessamentoRetorno = downloadLogProcessamentoRetorno;
